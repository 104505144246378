import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import Maturity from "@containers/localization-strategy/maturity";
import LocStrategy from "@containers/localization-strategy/strategy";
import IntroArea from "@components/introarea/layout-2";
import BoxSection from "@components/BoxSection/layout-three/layout-3";
import CtaArea from "@containers/cta/layout-02";
const LocalizationStrategyPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo title="Localization Strategy" 
      />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["localization-strategy-header-data"]} />
        <Maturity data={content["localization-strategy-maturity-model"]} />
        <IntroArea
          layout={2}
          data={content["localization-strategy-intro-body"]}
        />
        <LocStrategy data={content["translation-strategy-body"]} />
        <BoxSection
          layout={4}
          data={content["translation-localization-approch-body"]}
        />
        <CtaArea data={content["cta-data"]} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query localizationStrategyPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "localization-strategy" }
      pageType: { eq: "innerpage" }
    ) {
      content {
        ...PageContent
      }
    }
  }
`;

LocalizationStrategyPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default LocalizationStrategyPage;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import Text from "@ui/text";
import Heading from "@ui/heading";
// import Image from "@ui/image";
import Anchor from "@ui/anchor";

import {
  LocStrategyWrapper,
  LocStrategyInner,
  LocStrategyBox,
  LocStrategyIconWrap,
  LocStrategyCount,
  LocStrategyTextWrap,
} from "./style";

export default function LocStrategy({
  headingStyle,
  subheadingStyle,
  descStyle,
  data,
}) {
  return (
    <LocStrategyWrapper>
      <LocStrategyInner>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle
                subtitle={data.section_title.subtitle}
                title={data.section_title.title}
                description={data.section_title.description}
              />
            </Col>
          </Row>

          <Row>
            {!!data.items &&
              data.items.map((el, index) => (
                <Col lg={6} key={index}>
                  <LocStrategyBox>
                    <LocStrategyIconWrap>
                      <LocStrategyCount>{el.id}</LocStrategyCount>
                    </LocStrategyIconWrap>
                    <LocStrategyTextWrap>
                      <Heading {...subheadingStyle}>{el.subject}</Heading>
                      <Heading {...headingStyle}>{el.title}</Heading>
                      <Text className="mt-5" {...descStyle}>
                        {el.description}
                      </Text>
                      {!!el.path && (
                        <Anchor path={el.path} key={index}>
                          Read More <i className="ms-2 fal fa-angle-right"></i>
                        </Anchor>
                      )}
                    </LocStrategyTextWrap>
                  </LocStrategyBox>
                </Col>
              ))}
          </Row>
        </Container>
      </LocStrategyInner>
    </LocStrategyWrapper>
  );
}

LocStrategy.propTypes = {
  SectionTitleStyle: PropTypes.object,
  descStyle: PropTypes.object,
};
LocStrategy.defaultProps = {
  subheadingStyle: {
    as: "h5",
    fontSize: "15px",
    color: "#f94f19",
    fontweight: 400,
  },
  headingStyle: {
    as: "h3",
    fontSize: "30px",
    fontweight: 700,
  },
  descStyle: {
    fontSize: "17px",
  },
};

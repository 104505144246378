import styled, { device, themeGet } from "@styled";
import quoteIcon from "@data/images/icons/quoteSmall-icon.png";

export const LocStrategyWrapper = styled.section`
  padding: 85px 0;
`;
export const LocStrategyInner = styled.div``;
export const LocStrategyBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  max-width: 550px;
  ${device.small} {
    flex-direction: row;
  }
`;
export const LocStrategyIconWrap = styled.div`
  background: url(${quoteIcon});
  min-width: 70px;
  width: 70px;
  height: 70px;
  margin-top: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${themeGet("colors.primary")};
  font-size: 30px;
  font-weight: bold;
`;
export const LocStrategyCount = styled.span`
  margin-right: 14px;
  margin-bottom: 4px;
  color: ${themeGet("colors.primary")};
`;
export const LocStrategyTextWrap = styled.div`
  h5 {
    text-transform: uppercase;
    font-weight: 400;
  }
  h3 {
    font-size: 22px;
    ${device.small} {
      font-size: 30px;
    }
  }
  p {
    margin-top: 20px !important;
    ${device.small} {
      margin-top: 40px !important;
    }
  }
  a {
    color: ${themeGet("colors.primary")};
    transition: ${themeGet("transition")};
    &:hover {
      transform: translateY(-3px);
    }
  }
`;

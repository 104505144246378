import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Text from "@ui/text";
import {
  MaturityWrapper,
  MaturityInner,
  MaturityList,
  MaturityItem,
} from "./style";

const Maturity = ({ data }) => {

  return (
    <MaturityWrapper id={data.section}>
      <MaturityInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                subtitle={data.section_title?.subtitle}
                title={data.section_title?.title}
                description={data.section_title?.description}
              />
              <MaturityList>
                {data &&
                  data.items.map((el, index) => (
                    <MaturityItem key={index}>
                      <Text as="span">{el.id}</Text>
                      <Text as="span">{el.title}</Text>
                      <Text as="span">{el.description}</Text>
                    </MaturityItem>
                  ))}
              </MaturityList>
            </Col>
          </Row>
        </Container>
      </MaturityInner>
    </MaturityWrapper>
  );
};

export default Maturity;

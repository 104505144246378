import styled, { themeGet, device } from "@styled";

export const MaturityWrapper = styled.section`
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 110px 0;
`;
export const MaturityInner = styled.div``;
export const MaturityList = styled.ul`
  margin-top: 50px;
`;
export const MaturityItem = styled.li`
  display: grid;
  grid-template-columns: 90px 1fr;
  grid-template-rows: 110px 110px;

  ${device.small} {
    grid-template-columns: 90px 1fr 1fr;
    grid-template-rows: 110px;
  }
  &:first-child {
    grid-template-columns: 90px 1fr;
    ${device.small} {
      grid-template-columns: 90px 1.2fr 1fr;
    }
  }
  &:nth-child(2) {
    grid-template-columns: 90px 1fr;
    opacity: 0.9;
    ${device.small} {
      grid-template-columns: 90px 1fr 1fr;
    }
  }
  &:nth-child(3) {
    grid-template-columns: 90px 1fr;
    opacity: 0.8;
    ${device.small} {
      grid-template-columns: 90px 0.8fr 1fr;
    }
  }
  &:nth-child(4) {
    grid-template-columns: 90px 1fr;
    opacity: 0.7;
    ${device.small} {
      grid-template-columns: 90px 0.6fr 1fr;
    }
  }
  &:last-child {
    grid-template-columns: 90px 1fr;
    opacity: 0.6;
    ${device.small} {
      grid-template-columns: 90px 0.4fr 1fr;
    }
  }
  span {
    border: 1px solid ${themeGet("colors.secondaryDark")};
    background: linear-gradient(
      320deg,
      ${themeGet("colors.secondaryDark")},
      ${themeGet("colors.secondary")}
    );
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 1.3;
    ${device.small} {
      font-size: 18px;
    }
    ${device.medium} {
      font-size: 20px;
    }
    ${device.large} {
      font-size: 26px;
    }

    &:first-child {
      justify-content: center;
    }
    &:nth-child(2) {
      padding-left: 20px;
      ${device.medium} {
        padding-left: 40px;
      }
    }
    &:last-child {
      background: #fff;
      color: #464646;
      font-weight: 400;
      padding-left: 20px;
      font-size: 14px;
      grid-column: 2/4;
      ${device.small} {
        font-size: 14px;
        grid-column: auto;
      }
      ${device.medium} {
        font-size: 16px;
        padding-left: 40px;
      }
      ${device.large} {
        font-size: 20px;
      }
    }
  }
`;
